import * as React from "react"
import Layout from "../../../../../../components/layout";
import Seo from "../../../../../../components/seo";
import * as styles from "./index.module.css"

const HelpBraintrainingTransferImportAndroidPage = () => {
    return (
        <Layout isTop={false}>
            <Seo title="データ引き継ぎ"
                 description="データ引き継ぎ方法について"/>
            <article className={styles.article}>
                <section className={styles.attention}>
                    <p className={styles.strong}>以降の手順は新しいスマホで行います。新しいスマホを手元にご用意ください。</p>
                    <p>※ 24時間以内に手続きを行う必要があります。24時間を過ぎた場合は、再度初めから行ってください。</p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.h2}>毎日脳トレアプリのインストール</h2>
                    <p>新しいスマホに毎日脳トレのアプリをインストールします。</p>
                    <p>アプリストアで「毎日脳トレ」で検索するか、以下のQRコードを読み取ってください。</p>
                    <img src="https://storage.googleapis.com/nullhouse-info/qr/braintraining.png"
                         alt="https://brain-training.onelink.me/qCzj/friend"
                         width="140px"
                         height="140px"
                    />
                </section>

                <section className={styles.section}>
                    <h2 className={styles.h2}>データ引き継ぎページを開く</h2>
                    <p>新しいスマホで毎日脳トレのアプリを開いて、設定 → 機種変更時のデータ引き継ぎ をタッチします。</p>
                    <img src="https://storage.googleapis.com/nullhouse-info/help/braintraining/transfer/android_setting_import.png"
                         alt="機種変更時のデータ引き継ぎ"
                         width="258px"
                         height="230px"
                    />
                </section>

                <section className={styles.section}>
                    <h2 className={styles.h2}>Googleアカウントでログイン</h2>
                    <p>Googleのアカウントでログインします。<br/>必ず、古いスマホでログインしたものと同じアカウントでログインしてください。</p>
                </section>

                <section className={styles.section}>
                    <h2 className={styles.h2}>完了</h2>
                    <p>データが正常に引き継がれていれば完了です。</p>
                    <img src="https://storage.googleapis.com/nullhouse-info/help/braintraining/transfer/android_import_complete.png"
                         alt="データ引き継ぎ完了"
                         width="270px"
                         height="223px"
                    />
                </section>
            </article>
        </Layout>
    )
}

export default HelpBraintrainingTransferImportAndroidPage
